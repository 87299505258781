@import './theme';

//-----------------------------------------------------------------------------

html, body {
  height: 100%;
  overflow: hidden;
}

//-----------------------------------------------------------------------------

::-moz-selection {
  background: #000;
  color: #fff;
}

::selection {
  background: #000;
  color: #fff;
}

//-----------------------------------------------------------------------------

a {
  color: inherit;
  text-decoration: none;
}

p {
  margin-bottom: 1em;
}

ul {
  list-style-position: inside;

  li {
    padding-left: 0.5em;
  }
}

//-----------------------------------------------------------------------------

.ivu-spin-fix {
  z-index: 1000
}

.ivu-form-item {
  margin-bottom: 1rem;

  &:last-child {
    margin-bottom: 0;
  }
}

.ivu-form-item-error-tip {
  padding-top: 0.2em;
}

.ivu-input:focus {
  box-shadow: none;
}

.ivu-btn:focus, .ivu-btn-primary:focus {
  box-shadow: none;
}

.ivu-select-visible .ivu-select-selection {
  box-shadow: none;
}

.ivu-input-number:focus {
  box-shadow: none;
}

textarea.ivu-input {
  font-size: inherit;
}

.ivu-select-dropdown {
  max-height: 70%;
  //border: 1px solid $col-dark-border;
  box-shadow: 2px 2px 5px 1px rgba($col-dark-border, 0.8);
}

.ivu-dropdown-item .ivu-icon {
  line-height: 100%;
  font-size: 14px;
  margin-right: 0.5em;
}

.ivu-cascader-menu {
  min-width: 180px;
  height: auto;
  max-height: 320px;
}

.ivu-modal-body {
  position: relative;
}

.ivu-btn > .ivu-icon {
  line-height: 100%;
  font-size: 14px;
}

.ivu-poptip-popper[x-placement^=top] .ivu-poptip-arrow {
  border-top-color: $col-dark-border;
}

.ivu-poptip-popper[x-placement^=bottom] .ivu-poptip-arrow {
  border-bottom-color: $col-dark-border;
}

.ivu-select-dropdown, .ivu-poptip-inner {
  border: 1px solid $col-dark-border;
  box-shadow: 0 1px 6px rgba(0,0,0,.6)
}

.ivu-tag {
  user-select: none;
}

//-----------------------------------------------------------------------------

.ivu-select.inline-text {
  display: inline-block;
  width: auto;

  .ivu-select-selection {
    color: $col-primary-dark;
    line-height: 0.9em;
  }

  &.ivu-select-disabled .ivu-select-selection {
    color: $col-content;
  }
}

//-----------------------------------------------------------------------------

.configuration-select {
  .ivu-cascader {
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-align: left;
  }

  .ivu-cascader-rel {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

//-----------------------------------------------------------------------------

.hint {
  font-style: italic;
  line-height: 140%;
  color: $col-sub;
  margin-bottom: 1em;
}

.message {
  font-size: 1.16em;
  color: $col-title;
  padding: 1rem 0;
}

//-----------------------------------------------------------------------------

.fg-primary {
  color: $col-primary;
}

.fg-error {
  color: $col-error;
}

.fg-info {
  color: $col-info;
}

.fg-success {
  color: $col-success;
}

.fg-warning {
  color: $col-warning;
}

//-----------------------------------------------------------------------------

.clickable {
  cursor: pointer;
  color: $col-primary;
}
