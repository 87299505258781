$col-primary: #2d8cf0;
$col-primary-light: #5cadff;
$col-primary-very-light: #e8f2fc;
$col-primary-dark: #2b85e4;

$col-info: #2db7f5;
$col-success: #19be6b;
$col-warning: #ff9900;
$col-error: #ed4014;

$col-title: #17233d;
$col-content: #515a6e;
$col-sub: #808695;

$col-border: #dcdee2;
$col-disabled: #c5c8ce;
$col-divider: #e8eaec;
$col-background: #f8f8f9;

$col-dark-border: rgb(47, 47, 47);

$input-border-radius: 4px;

$cell-height: 20px;
$cell-h-padding: 4px;

$z-cell-dropdown: 2000;
$z-cell-tooltip: 3000;
$z-cell-suggestions: 2000;
$z-table-blocker: 5000;

$menu-bar-height: 48px;
